<template>
  <div>
    Polars
  </div>
</template>

<script>
export default {
  name: 'Polars',
  props: {
  },
}
</script>

<style>
</style>
